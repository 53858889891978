@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';

.twoImageSection {
    padding: 80px 0;
    row-gap: 24px;
    @include ipad {
        padding: 60px 0;
    }

    @include mobile {
        padding: 40px 0;
    }
    .media {
        width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
        object-fit: cover;
    }

    .VideoWrapper {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        height: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            border: none;
            display: block;
        }
    }
}